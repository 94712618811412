import React from 'react';
import './App.css';
import logo from './Email-trash-icon.png'
import screenshot from './slack-integration.png'
import preview from './email-preview.png'

import { Paper, Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as qs from 'qs';

import Helmet from "react-helmet";

const styles = theme => ({
	"@global": {
		body: {
			backgroundImage: "url('/images/background.jpg')",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
			backgroundSize: "cover",
			backgroundAttachment: "fixed",
      height: "100%"
		},
	}
});

class SlackLanding extends React.Component {
  
  constructor(props) {
    super(props); 
    this.passthrough = qs.parse(props.location.search, { ignoreQueryPrefix: true }).passthrough;
  }

  openCheckout() { 
      const Paddle = window.Paddle;
      Paddle.Checkout.open({
        product: 594725,
        passthrough: this.passthrough
       });
  }

  render() {

      const { params } = this.props.match
      console.log(this.passthrough);
      
      if (params.response == 'success') {
        return (

          <div className="App">
  
            <Helmet>  
              <title>{'my24h.email - disposable service'}</title>
            </Helmet>   
  
            <div className="App-header">
              <Typography variant="h3"> 
                  <b>my24h</b><i>.e-mail</i> 
              </Typography>
            </div>
  
            <div className="SlackLanding">
  
                  <Paper elevation={3}>
              
                  <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br/>
                    <Typography variant="h4">
                      <b>Ta-da! Congratulations!</b>
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                              <img src={logo} alt="Logo" width="50px" />
                          </TableCell>
                          <TableCell>
                          <Typography>
                          <b>@my24h.email</b> app is now installed in <a href="https://www.slack.com">selected Slack workspace</a>.<br clear="left"/>
                            Open app home to get help about available commands and features.
                          </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
  
                  </Grid>  
                  </Grid>
                  </Paper>
          </div>
        </div>
        );
      } else if (params.response == 'expired') {
        return (

          <div className="App">
  
            <Helmet>  
              <title>{'my24h.email - disposable service'}</title>
            </Helmet>   
  
            <div className="App-header">
              <Typography variant="h3"> 
                  <b>my24h</b><i>.e-mail</i> 
              </Typography>
            </div>
  
            <div className="SlackLanding">
  
                  <Paper elevation={3}>
                  <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br/>
                    <Typography variant="h4">
                      <b>Content expired!</b>
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                              <img src={logo} alt="Logo" width="50px" />
                          </TableCell>
                          <TableCell>
                          <Typography>
                            This content is no more available. Disposable mailbox is probably expired.
                          </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
  
                  </Grid>  
                  </Grid>
                  </Paper>
          </div>
        </div>
        );
      } else if (params.response == 'support') {
          return (
  
            <div className="App">
    
              <Helmet>  
                <title>{'my24h.email - disposable service'}</title>
              </Helmet>   
    
              <div className="App-header">
                <Typography variant="h3"> 
                    <b>my24h</b><i>.e-mail</i> 
                </Typography>
              </div>
    
              <div className="SlackLanding">
    
                    <Paper elevation={3}>
                    <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <br/>
                      <Typography variant="h4">
                        <b>Customer Support</b>
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                                <img src={logo} alt="Logo" width="50px" />
                            </TableCell>
                            <TableCell>
                            <Typography>
                            <b>my24h.email</b> Slack app customer support is provided by email.<br clear="left"/>Please <a href="mailto:support@my24h.email">contact us</a> to obtain technical and commercial assistance. 
                            </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
    
                    </Grid>  
                    </Grid>
                    </Paper>
            </div>
          </div>
          );
      } else if (params.response == 'subscribe') {

        return (

          <div className="App">
  
            <Helmet>  
              <title>{'my24h.email - disposable service'}</title>
            </Helmet>   
  
            <div className="App-header">
              <Typography variant="h3"> 
                  <b>my24h</b><i>.e-mail</i> 
              </Typography>
            </div>
  
            <div className="SlackLanding">
  
                  <Paper elevation={3}>
                  <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br/>
                    <Typography variant="h4">
                      <b>Subscribe</b>
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                              <img src={logo} alt="Logo" width="50px" />
                          </TableCell>
                          <TableCell>
                          <Typography>
                              To continue to use <b>my24h.email</b> Slack app, please to subscribe. Use the button to start the checkout process with our partner Paddle.com.
        
                              <Button variant="primary" onClick={this.openCheckout.bind(this)}>Subscribe Now!</Button>{' '}
                          </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
  
                  </Grid>  
                  </Grid>
                  </Paper>
          </div>
        </div>
        );
    }  else if (params.response == 'install') {
        return (
        <div>
  
          <Helmet>  
            <title>{'my24h.email - disposable service'}</title>
          </Helmet>   
          <div className="App-header">
              <Typography variant="h3"> 
                  <b>my24h</b><i>.e-mail</i> 
              </Typography>
            </div>

          <div className="InstallLanding">
            <Paper variant="outlined" className="InstallLandingContent">

                  <Grid container spacing={3}>
                      <Grid item xs={6}>   
                      <center><img src={screenshot} alt="Logo" width="80%" /></center>
                      </Grid>
                      <Grid item xs={6}>   
                          <center><br/><br/>Add a disposable mailbox to
                          <Typography variant="h2">your Slack channels</Typography>
                          </center>
                      </Grid>
                      <Grid item xs={6}><center><br/><br/>
              <a href="https://slack.my24h.email/authorize">
              <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"/></a>
              </center></Grid>
                      <Grid item xs={6}>   
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                                <img src={logo} alt="Logo" width="50px" />
                            </TableCell>
                            <TableCell>
                            <Typography>
                            <b>my24h.email</b> app allows to create one or more disposable mailboxes linked to a Slack channel. You can receive and share emails during your meetings or automate mail workflows.
                            </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      </Grid>
                      <Grid item xs={6}>   
                          <center><br/><br/>Install app
                          <Typography variant="h2">1</Typography>
                          Use the "Add to Slack" button 
                          </center>
                      </Grid>
                      <Grid item xs={6}>   
                          <center><br/><br/>Add it to your channel
                          <Typography variant="h2">2</Typography>
                          <pre>/invite @my24h.email</pre> 
                          </center>
                      </Grid>
                      <Grid item xs={6}>   
                          <center><br/><br/>Create a new disposable mailbox
                          <Typography variant="h2">3</Typography>
                          <pre>@my24h.email create</pre> 
                          </center>
                      </Grid>
                      <Grid item xs={6}>   
                          <center><br/><br/>Send emails
                          <Typography variant="h2">4</Typography>
                          to disposable mailbox address
                          </center>
                      </Grid>           
                      <Grid item xs={6}>   
                          <center><br/><br/>Read
                          <Typography variant="h2">5</Typography>
                          directly in Slack! 
                          </center>
                      </Grid>    
                      <Grid item xs={6}>   
                          <center><img src={preview} alt="Logo" width="60%" />
                          </center>
                      </Grid>            
                  </Grid>
            
            </Paper>
            <div className="App-footer">
                  <Typography variant="overline"><a href="/">home</a> | <a href="/privacy">privacy policy</a></Typography>
            </div>

          
          </div>
        </div>
        );
      } else { 
        return (

          <div className="App">
  
            <Helmet>  
              <title>{'my24h.email - disposable service'}</title>
            </Helmet>   
  
            <div className="App-header">
              <Typography variant="h3"> 
                  <b>my24h</b><i>.e-mail</i> 
              </Typography>
            </div>
  
            <div className="SlackLanding">
  
                  <Paper elevation={3}>
                  <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <br/>
                    <Typography variant="h4">
                      <b>Ops! We are sorry!</b>
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                              <img src={logo} alt="Logo" width="50px" />
                          </TableCell>
                          <TableCell>
                          <Typography>
                            Something embarrassing has happened. <br/> Please try to install <b>@my24h.email</b> app again.
                          </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
  
                  </Grid>  
                  </Grid>
                  </Paper>
          </div>
        </div>
        );
      }

      
  }
  
}

export default  withStyles(styles)(SlackLanding);
