import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Paper, Typography, Divider} from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import MailIcon from '@material-ui/icons/Mail';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DeleteIcon from '@material-ui/icons/Delete';
import HttpIcon from '@material-ui/icons/Http';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {isMobile} from 'react-device-detect';
import rapidapilogo from './connect-on-rapidapi.png';

export default function AboutSnackbar() {
  const [open, setOpen] = React.useState(!isMobile);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

//   <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
//   <CloseIcon fontSize="small" />
// </IconButton>  

  

  return (
    <div>
      <Snackbar
               key='show_info'
               anchorOrigin={
                 isMobile ? { vertical: 'bottom', horizontal: 'left'} : { vertical: 'top', horizontal: 'left'}
               }
             
              open={!open}
              // autoHideDuration={6000}
              onClose={handleClose}>
        <Button variant="contained" onClick={handleClick}>show info</Button>          
      </Snackbar>

      <Snackbar
        key='info_panel'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}>
  
            <Paper>
 
            <List>
                <ListItem>
                <ListItemAvatar>
                  <MailIcon/>
                </ListItemAvatar>   
                <ListItemText
          primary="Inbox activation"
          secondary={
            <React.Fragment>
            {"Instantly create an unlimited amount of inboxes."} 
            {isMobile ? <a href="https://www.powtoon.com/c/g7acuDP1IQd/1/m" target="_blank">Watch a video</a> : ''}
            </React.Fragment>
          }
          />
                </ListItem>
                <ListItem>
                <ListItemAvatar>
                  <VerifiedUserIcon/>
                </ListItemAvatar>  
                <ListItemText
          primary="Tests"
          secondary={
            <React.Fragment>
              {"For email receipt & workflow tests."}
            </React.Fragment>
          }
          />
                </ListItem>

                <ListItem>
                <ListItemAvatar>
                  <DeleteIcon/>
                </ListItemAvatar>  
                <ListItemText
          primary="Disposable"
          secondary={
            <React.Fragment>
              {"Any time you need a disposable email address."}<br></br>
              {"Avoid spam and stay safe."}
            </React.Fragment>
          }
          />
                </ListItem>

                <ListItem>
                <ListItemAvatar>
                  <HttpIcon/>
                </ListItemAvatar>  
                <ListItemText
          primary="Amazing API features"
          secondary={
            <React.Fragment>
              {"Confirm subscriptions with auto-click feature."}<br></br>
              {"Full length rendering of email body."}              
            </React.Fragment>
          }
          />
                </ListItem>

                <ListItem>
                <ListItemAvatar>
                  <AutorenewIcon/>
                </ListItemAvatar>  
                <ListItemText
          primary="Automate"
          secondary={
            <React.Fragment>
              {"All you need to add disposable emails to your projects."}
            </React.Fragment>
          }
          />
                </ListItem>
                <ListItem>
                <ListItemAvatar>
                  <Avatar alt="RapidApi Logo" src={rapidapilogo} />
                </ListItemAvatar>
                <ListItemText
          primary="Subscribe"
          secondary={
            <React.Fragment>
               We are now available on the <a href="https://rapidapi.com/vittorio.nardone/api/easy-fast-temp-mail/details" target="_blank">RapidAPI Marketplace</a>              
            </React.Fragment>
          }
          />
                </ListItem>
            <ListItem>
              <div>
                  <Button variant="contained" onClick={handleClose}>hide me</Button> 
              </div>
            </ListItem>
            </List>            
            </Paper>  
            
        </Snackbar>

    </div>
  );
}
