import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Snackbar, Paper } from '@material-ui/core';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles({

});

export interface VideoDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function VideoDialog(props: VideoDialogProps) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Paper>
      <iframe title="introduction-video" width="640" height="360" src="https://www.powtoon.com/embed/g7acuDP1IQd/" frameborder="0"></iframe> 
      </Paper>
    </Dialog>
  );
}

export default function VideoSnackbar() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  if (isMobile) {
    return ( '' );
  } else {
    return (
      <div>
        <Snackbar 
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open="true"
                // autoHideDuration={6000}
                onClose={handleClose}
                message="Discover amazing API features"
                action={
                  <React.Fragment>
                        <Button variant="contained" onClick={handleClickOpen}>
                            watch video
                        </Button>
                  </React.Fragment>

                }>
        
        </Snackbar>
        <VideoDialog open={open} onClose={handleClose} />
      </div>
    );
  };
}
