import React from 'react';
import './App.css';
import logo from './Email-trash-icon.png'

import { Button, Paper, Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import Helmet from "react-helmet";

const styles = theme => ({
	"@global": {
		body: {
			backgroundImage: "url('/images/background.jpg')",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
			backgroundSize: "cover",
			backgroundAttachment: "fixed",
      height: "100%"
		},
	}
});

class PrivacyLanding extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
        return (

          <div>
  
            <Helmet>  
              <title>{'my24h.email - disposable service'}</title>
            </Helmet>   
  
            <div className="App-header">
              
             
              <Typography variant="h3"> 
                  <b>my24h</b><i>.e-mail</i> 
              </Typography>         
            </div>
  
            <div className="PrivacyLanding">
              <Paper variant="outlined" className="PrivacyLandingContent">
          
              
              
                  <Typography variant="h5"><br/>Overview</Typography>
                  <br/>My24h.email is a disposable email platform. My24h.email (alternately, "we", "us" or "our") respects your privacy and is committed to complying with this privacy policy ("Privacy Policy"), which describes what information we collect about you, including how we collect it, how we use it, with whom we may share it and what choices you have regarding our use of your information. This Privacy Policy applies to information collected on our website located at www.my24h.email(the "Website").
                  
<Typography variant="h5"><br/>Changes to our Privacy Policy</Typography>
<br/>We may change this Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the version date located at the bottom of this Privacy Policy. All such updates and amendments are effective immediately upon notice thereof, which we may give by any means, including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Website. You should view this Privacy Policy often to stay informed of changes that may affect you, as your continued use of the Website signifies your continuing consent to be bound by this Privacy Policy. We expressly reserve the right to make any changes to this Privacy Policy at any time, without prior notice to you. The version of this Privacy Policy posted here. on each respective date you visit the Website shall be the Privacy Policy applicable to your access and use of the Website on that date. Our electronically or otherwise properly stored copies of this Privacy Policy shall be deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this Privacy Policy which were in force on each respective date you visited the Website. This Privacy Policy is not intended to and does not create any contractual or other legal right in or on behalf of any party other than My24h.email.
<br/>
<Typography variant="h5"><br/>What Information Do We Collect?</Typography>
<br/>As described in detail below, we may collect certain identifying information from or about you in connection with your use of, or your submissions to, the Website (collectively, the "Collected Information"). Collected Information includes, without limitation, any information that may be used to identify an individual, including, but not limited to, a first and last name, a home, business, or other physical address, business name, a phone number, an email address, employer name, employer information (i.e., employer address and other identifying information), and credit card number (and related information such as expiration date). My24h.email uses the information we collect to provide the services that are available via the Website.

<Typography variant="h5"><br/>Cookies and Usage Reports</Typography>
<br/>By placing a small file known as a "cookie" on your computer (or other device), My24h.email's and its third party service providers' servers passively gather information about all visitors' use of the Website for several reasons, including, but not limited to, the following: statistics collection and analysis, Website optimization, analytics (as described below), market research, and maintenance of user login information. The information that we and our third party service providers track with cookies includes, but is not limited to, the type of browser (such as Google Chrome or Internet Explorer) and Internet-connected devices being used to access the Website, your Internet protocol ("IP") address, your home domain or Internet service provider, your referrer URL (which is the URL for the website that you were viewing prior to visiting the Website), how you were directed to the Website, which specific pages you access on the Website, how long you view each page, the time and date you access our Website and the total number of visitors to the Website and any portions thereof. We, and/or our third party service providers, may use the information collected from cookies or similar files on your computer for security purposes (such as authentication), to facilitate site navigation and to personalize your experience while visiting the Website. This data helps us and our third party service providers improve our respective product and services.

<br/>Most popular Internet browser applications will allow you to configure the browser so as not to accept cookies. However, setting your browser to reject cookies may, in certain instances, prevent you from taking full advantage of the Website (or some portion thereof) and its capabilities, or cause some features of the Website to function improperly or more slowly.

<Typography variant="h5"><br/>Google AdSense and Web Beacons</Typography>
<br/>Our websites may use Google AdSense, a web analysis service provided by Google, in order to better understand your use of our websites and how we can improve them. Google AdSense collects information such as how often users visit a website, what pages you visit when you do so, and what other websites you used prior to coming to such website. Google AdSense collects only the IP address assigned to you on the date you visit a website, rather than your name or other identifying information. We do not combine the information collected through the use of Google AdSense with your personal information. Although Google AdSense plants a permanent cookie on your web browser to identify you as a unique user the next time you visit a website, the cookie cannot be used by anyone but Google. Google's ability to use and share information collected by Google AdSense about your visits to My24h.email website is restricted by the Google AdSense Terms of Use and the Google Privacy Policy. Google utilizes the data collected to track and examine the use of the My24h.email website, to prepare reports on its activities and share them with other Google services. Google may use the data collected on the My24h.email website to contextualize and personalize the ads of its own advertising network. To more fully understand how Google may use the data it collects on the My24h.email website, please review "How Google uses data when you use our partners' sites or apps,"

<br/>Some of the areas of the Website may contain electronic images known as web beacons (sometimes known as clear gifs) that allow My24h.email, and its third party providers or affiliates, to count and track users who have visited certain portions of the Website. Web beacons collect only limited information and do not collect any of your personal information.

<Typography variant="h5"><br/>Correspondence</Typography>
<br/>We appreciate your questions and comments about the Website and welcome your messages at our our support email address, support@my24h.email. If you correspond with My24h.email through the Website or via email, the Collected Information may include the content of, and metadata regarding, any correspondence you may have with us. We may share your messages with those within our organization who are most capable of addressing the issues contained in your message. We may archive your message for a certain period of time or discard it.

<Typography variant="h5"><br/>How Can I Opt-Out of Promotional Correspondence?</Typography>
<br/>We may send you emails with promotional offers only if you opt-in to receiving such emails. If you would no longer like to receive information, newsletters or other promotional messages from us, please click the link at the bottom of any such email you receive from us and follow the instructions, or alternatively you may contact us, support@my24h.email with the word "UNSUBSCRIBE" or "REMOVE" in the subject line. Your email address will be removed from our marketing list. Please allow us a reasonable period of time in order to satisfy your request, as some promotions may already be in process.

<Typography variant="h5"><br/>Online Account Management</Typography>
<br/>If you elected to subscribe to any online services provided via the Website, including the ability to access any password-protected areas of the Website, you will be required to submit personal information (including some Collected Information) for the purpose of verifying your identity and your authority to manage the account. This Collected Information will be used by My24h.email, our agents, suppliers and third-party service providers for internal use, to respond to your service requests and for any other lawful purpose.

<Typography variant="h5"><br/>How Do We Use The Information That We Collect?</Typography>
<br/>My24h.email and our third party services providers collect and use the Collected Information to operate the Website and deliver the services, including to provide certain services and improve the user experience, address Website integrity or security issues, and conduct research and analysis to maintain, protect, develop, and improve the Website and additionally, use any information submitted by users as described below or elsewhere in this Privacy Policy or the Terms of Use. We may send you information that you request from us, promotional materials regarding My24h.email, and any other communication for any other legitimate and lawful business purposes. Additionally, we may retain and use certain Collected Information to facilitate and otherwise address certain inquiries or requests regarding the services or the Website, or respond to your questions or requests for information about or from My24h.email. We may also use Collected Information as necessary to ensure compliance with any My24h.email policies and any applicable law, regulation or order.

<br/>Except as set forth in this Privacy Policy, My24h.email will not use, disclose, or transfer your Collected Information unless:
<br/>
<br/>(1) you expressly authorize My24h.email to do so,
<br/>(2) it is necessary to allow My24h.email service providers, agents, or other third parties to provide products or services for or to My24h.email,
<br/>(3) it is necessary in order to provide the Website or the services,
<br/>(4) My24h.email is sending you other information that may be useful to you,
<br/>(5) subject to applicable contractual or legal restrictions, it is disclosed to entities that perform marketing services on My24h.email behalf or to other entities with whom My24h.email has joint marketing agreements,
<br/>(6) it is necessary to protect the confidentiality or security of your records,
<br/>(7) it is necessary in connection with other business purposes including, without limitation, verifying identity, age, and/or payment details, investigating complaints about the Website, risk assessment, security, fraud and crime prevention/detection, monitoring, research and analysis, marketing, customer purchasing preferences and trends and dispute resolution,
<br/>(8) it is necessary to comply with law enforcement, governmental mandate, or other legal requirement, if appropriate, for your protection or in connection with an investigation or prosecution of possible unlawful activity;
<br/>(9) subject to applicable contractual or legal restrictions, it is necessary in connection with a sale of all or substantially all of the assets of My24h.email or the merger of My24h.email into another entity or any consolidation, share exchange, combination, reorganization, or like transaction in which My24h.email is not the survivor, or
<br/>(10) it is otherwise necessary for My24h.email to disclose it as required or permitted by law.
<br/>
<Typography variant="h5"><br/>With Whom Do We Share Information That We Collect?</Typography>
<br/>In addition to the uses mentioned or described elsewhere in this Privacy Policy or in the Terms of Use, we may catalog and add Collected Information to our database.

<br/>For the private upgraded My24h.email plan, it will be necessary for us to share some of your Collected Information with the third party payment processors in order to process your requested transaction. We do not sell, rent, or lease our user lists or the identity of individual users to third parties. However, My24h.email may use and disclose certain aggregated, anonymized information, such as Website usage data, to our trusted business partners. Such information may also be shared with other users or the general public for advertising, informational, or comparison purposes. However, such information shall not be personally identifiable to any user.

<br/>We may also disclose Collected Information if we become subject to a subpoena or court order, or if we are otherwise legally required to disclose information. We may also use and disclose Collected Information to establish or exercise our legal rights, to enforce the Terms of Use, to assert and defend against legal claims, or if we believe such disclosure is necessary to investigate, prevent, or take other action regarding actual or suspected illegal or fraudulent activities or potential threats to the physical safety or well-being of any person.

<br/>As My24h.email grows and develops its business, it is possible that its corporate structure or organization might change or that it might merge or otherwise combine with, or that it or portions of its business might be acquired by, another company. In any such transactions, customer information generally is, and should be expected to be, one of the transferred business assets. Also, if any bankruptcy, liquidation or reorganization proceeding is brought by or against us, your information may be considered a company asset that may be sold or transferred to third parties.

<Typography variant="h5"><br/>What Choices Do You Have?</Typography>
<br/>When submitting information, corresponding, making requests for information, and otherwise interacting with My24h.email and its representatives through or in connection with the Website, you choose what information to supply or submit, whether you wish to receive further information, and how you may be contacted. Please take care to share only such information as is needed or that you believe is appropriate. Any promotional email we send to you will contain a link which will allow you to opt out of receiving any further correspondence. If you have created an online account, you may also change your correspondence preferences by logging into your account. You may disable your online account access at any time; however, this will not necessarily result in the deletion of any Collected Information.
<Typography variant="h5"><br/>How Do We Protect Information Collected About You?</Typography>
<br/>My24h.email takes commercially reasonable measures to secure and protect information transmitted via or stored on the Website. Nevertheless, no security system is impenetrable. We cannot guarantee that information that users of the Website may happen to transmit or otherwise supply will be totally secure. You agree to immediately notify us of any breach of Website security, this Privacy Policy, or the Terms of Use of which you become aware.

<br/>If you are using the public My24h.email system, you should have no expectation of privacy because anyone can read any email received and posted in any mailbox. Please do not use the public My24h.email system for anything that you want to keep private.

<Typography variant="h5"><br/>Linked Websites</Typography>
<br/>For your convenience, some hyperlinks may be posted on the Website that link to other websites not under our control. We are not responsible for, and this Privacy Policy does not apply to, the privacy practices of those sites or of any companies that we do not own or control. We cannot be responsible for the privacy practices of any such websites or pages not under our control and we do not endorse any of these websites or pages, the services or products described or offered on such sites or pages, or any of the content contained on those sites or pages. We encourage you to seek out and read the privacy policy of each website that you visit. In addition, should you happen to initiate a transaction on a website that our Website links to, even if you reached that site through the Website, the information that you submit to complete that transaction becomes subject to the privacy practices of the operator of that linked website. You should read that website's privacy policies to understand how personal information that is collected about you is used and protected.

<Typography variant="h5"><br/>User-Provided Content</Typography>
<br/>Any information, communications, or material of any type or nature that you submit (via entry, via email, or otherwise) to the Website is done at your own risk and without any expectation of privacy. We cannot control the actions of other users of the Website and we are therefore not responsible for any submissions contained on the Website. By visiting any of our pages that are contained on the Website, you are representing and warranting to us that you have reviewed this Privacy Policy and the Terms of Use and that you will abide by all such provisions contained therein.

<br/>Note the inherent design of the Public My24h.email system provides no privacy. All emails sent to this system are readable by anyone and in the public domain.
<Typography variant="h5"><br/>Children</Typography>
<br/>The Website is intended for a general audience and is not intended for use or view by children under 13 years of age without parental consent, and we do not knowingly collect information about children or sell products to children. Consistent with the Children's Online Privacy Protection Act, we will not knowingly collect any information from children under the age of 13. If you are under the age of 13, you must ask your parent or guardian to assist you in using the Website.

<Typography variant="h5"><br/>Visiting The Website From Outside European Community</Typography>
<br/>If you are visiting the Website, or any part thereof, from outside of the European Community, please be aware that your information may be transferred to, stored or processed in the European Community, where our servers are located and our central database is operated. The data protection and other laws of the European Community and other countries might not be as comprehensive as those in your country. By using any portion of the Website, you understand and consent to the transfer of your information to our facilities in the European Community and those third parties with whom we share it as described in this Privacy Policy.

<Typography variant="h5"><br/>Do Not Track Requests</Typography>
<br/>Certain state laws require that we indicate whether we honor "Do Not Track" settings in your browser concerning targeted advertising. "Do Not Track" is a standard that is currently under development. As it is not yet finalized, we adhere to the standards set out in this Privacy Policy and do not monitor or follow any Do Not Track browser requests.

<Typography variant="h5"><br/>Whom Can You Contact For More Information?</Typography>
<br/>If you have any questions or suggestions about the Website, My24h.email, our services, or our privacy practices, please contact us support@my24h.email.

<Typography variant="h5"><br/>Acknowledgement</Typography>
<br/>BY ACCESSING OR USING THE WEBSITE, OR ANY PORTION THEREOF, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND CONSENT TO THE PRIVACY POLICY, AND TO THE USES AND DISCLOSURES OF COLLECTED INFORMATION ABOUT YOU, THAT ARE DESCRIBED IN THIS PRIVACY POLICY AND YOU AGREE TO BE BOUND BY THE TERMS OF USE REFERENCED ABOVE.
     
<br/>

</Paper>
          
          </div>
          <div className="App-footer">
                  <Typography variant="overline"><a href="/">home</a></Typography>
            </div>
        </div>
        );
      

      
  }
  
}

export default  withStyles(styles)(PrivacyLanding);
