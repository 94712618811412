import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import App from './App';
import SlackLanding from './SlackLanding'
import PrivacyLanding from './Privacy'
import TosLanding from './Tos'
import notFound from './notFound'
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

// Replace with your Google Analytics tracking ID
ReactGA.initialize('UA-152537208-3'); 

const routing = (
    <Router>
      <div>
        <Switch>
            <Route exact path="/" component={App} />
            <Route path="/slack/:response" component={SlackLanding} />
            <Route path="/privacy" component={PrivacyLanding} />
            <Route path="/tos" component={TosLanding} />
            <Route component={notFound} />
        </Switch>
      </div>
    </Router>
  )

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
