import React from 'react';
import { TextField, Button, FormLabel, Typography, Paper } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';

class LoginForm extends React.Component {

    constructor(props) {
      super(props);
      this.state = {address: ''};
      
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

      this.recaptchaRef = React.createRef();
    }
  
    componentDidMount() {
      this.recaptchaRef.current.execute();
    }

    handleChange(event) {
      this.setState({address: event.target.value});
    }
  
    handleSubmit(event) {
        const recaptchaValue = this.recaptchaRef.current.getValue();
        if (recaptchaValue === '') {
           window.location.reload();
        } else { 
            console.log("Captcha value:", recaptchaValue);
        
          let tempAddress = 'random'
          if (this.state.address) {
            tempAddress = this.state.address + this.props.email_domain
          }

          fetch(this.props.apiEndpoint + 'create?address=' 
             + encodeURI(tempAddress)
             + '&captcha=' + recaptchaValue)
          .then(r =>  r.json().then(data => ({status: r.status, body: data})))
          .then(r => {
            console.log(r);
            console.log('Response from API: ' + r.body.message);
            if (r.status === 200) {
              this.props.changeAddress(r.body.address, r.body.sessionid);  
            }
        })
        .catch(console.log);
        }
        event.preventDefault();
    }
  
    render() {

      let search = window.location.search;
      let params = new URLSearchParams(search);
      if (params.get('address')) {
        this.state.address = params.get('address')
      } 

      return (
        <Paper>
          
          <p></p>
       
        <form onSubmit={this.handleSubmit}>
        <ReCAPTCHA
            sitekey={this.props.recaptcha_key}
            ref={this.recaptchaRef}
            size="invisible"
        />

          <TextField 
              value={this.state.address} 
              onChange={this.handleChange}
              placeholder="(type yours or login)"
              inputProps={{
                style: { textAlign: "right"}
              }} 
          />
          {isMobile ? 
          <FormLabel> {this.props.email_domain} <p></p> </FormLabel>
          :
          <TextField 
              value={this.props.email_domain} 
              disabled 
              inputProps={{
                style: { textAlign: "left" }
              }} 
          /> }
          
          <Button variant="contained" type="submit"> Login </Button>
  
        </form>
        <p></p>
          <Typography variant="body2">
           Choose your disposable mailbox address or simply login to create a random address. 
           </Typography>
        
        <p></p>
        </Paper>
        
      );
    }
  }

  export default LoginForm