import React from 'react';
import './App.css';
import LoginForm from './Login'
import EmailList from './List'
import { Paper, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import ReactGA from 'react-ga';
import Helmet from "react-helmet";

import VideoSnackbar from './Video';
import AboutSnackbar from './About';

import Grid from '@material-ui/core/Grid';
import {isMobile} from 'react-device-detect';


/////////////////////////////////////////////////////////////////////////////////
// Please change these values with:

//  - your APIEndpoint. It's an output value of AWS CloudFormation template 
const APIEndpoint = 'https://o801ct7e5g.execute-api.eu-west-1.amazonaws.com/v0/';

//  - your ReCaptcha Site Key. Get it at https://www.google.com/recaptcha/admin 
const ReCaptcha_SiteKey = "6LecAtIUAAAAAFCckREQNEanQtK28JYDjzTSfJ_f"; 

//  - your email domain
const email_domain = '@my24h.email';
/////////////////////////////////////////////////////////////////////////////////

const styles = theme => ({
	"@global": {
		body: {
			backgroundImage: "url('/images/background.jpg')",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
			backgroundSize: "cover",
			backgroundAttachment: "fixed",
      height: "100%"
		},
	}
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {address: '', sessionid: ''};
  }

  changeAddress(address, sessionid) {
    if (address !== '') {
      ReactGA.event({ category: "Mailbox", action: "Login",});
    } else {
      ReactGA.event({ category: "Mailbox", action: "Logout",});
    }
    this.setState({address: address, sessionid: sessionid});
  }


  componentDidMount  = () => ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);

  render() {
 
    if (this.state.address !== '') {
      return (
            <EmailList 
              address={this.state.address} 
              sessionid={this.state.sessionid}
              changeAddress={this.changeAddress.bind(this)} 
              apiEndpoint={APIEndpoint}/>
      ); 
    } else {
      return (
        <div>
        <div className="App">

          <Helmet>  
            <title>{'my24h.email - disposable service'}</title>
          </Helmet>   

          <div className="App-header">

          {isMobile ? 
          <Grid container >
            <Grid item xs={12}>
              <Typography variant="h3"> 
                <b>my24h</b>.e-mail 
              </Typography> 
            </Grid>
            <Grid item xs={12}>
              <Typography> 
                Your disposable e-mail service
              </Typography> 
            </Grid>

          </Grid> :

          <Grid container >
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Typography variant="h3"> 
                <b>my24h</b>.e-mail
              </Typography>
              <Grid item xs={12}>
              <Typography> 
                Your disposable e-mail service
              </Typography> 
            </Grid>
            </Grid>
            
            
            <Grid item xs={3}>
              <a href="/slack/install">
              <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"/></a>
            </Grid>
          </Grid> }
          </div>

              <div className= {isMobile ? "LoginMobile" : "LoginDesktop"}>
              
              <LoginForm 
                changeAddress={this.changeAddress.bind(this)} 
                apiEndpoint={APIEndpoint}
                recaptcha_key={ReCaptcha_SiteKey}
                email_domain={email_domain}/>
            
            </div> 
            </div>
              <AboutSnackbar/>
              <VideoSnackbar/>
              {!isMobile ? 
              <div className="App-footer">
                  <Typography variant="overline"><a href="/privacy">privacy policy</a> / <a href="/tos">terms of service</a> </Typography>
            </div> : ''}
          </div>

      );
    }
  }
}

export default  withStyles(styles)(App);
