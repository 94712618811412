import React from 'react';
import './App.css';

import { Paper, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import Helmet from "react-helmet";

const styles = theme => ({
	"@global": {
		body: {
			backgroundImage: "url('/images/background.jpg')",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center center",
			backgroundSize: "cover",
			backgroundAttachment: "fixed",
      height: "100%"
		},
	}
});

class notFound extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {

      return (

        <div className="App">

          <Helmet>  
            <title>{'my24h.email - disposable service'}</title>
          </Helmet>   

          <div className="App-header">
            <Typography variant="h3"> 
                <b>my24h</b><i>.e-mail</i> 
            </Typography>
          </div>

          <div className="SlackLanding">

                <Paper elevation={3}>
                  <br/>
                  <Typography variant="h4">
                    <b>404 - This is embarassing!</b>
                  </Typography>
                  <br/>
                </Paper>
        </div>
      </div>
      );
  }
  
}

export default  withStyles(styles)(notFound);
